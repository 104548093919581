<template>
  <div class="page-voice bg-main">
    <div class="sec tip">
      <h3 style="font-size: 0.9rem; color: #000">语音采集</h3>
      <p v-if="flag=='stop'">采集时间：{{detail.created_time}}</p>
      <p v-else>温馨提示：请熟悉并朗读下面语句，用正常语速朗读完毕提交， 完成采集（请保障声音清晰，周围环境无杂音）。录音时长控制在15秒-60秒之间才能采集成功。</p>
      <div>
大家好，我是[你的姓名]，目前在[具体岗位]岗位上工作。 我对工作充满热情和责任心，始终保持专注的态度，尽力确保工作的质量和效率。感谢有机会向大家介绍自己，期待着能在今后的工作中与各位有更多的交流与合作！
      </div>
    </div>
    <div class="sec opts">
      <div v-if="flag=='start'" class="box-start">
        <img src="@/assets/ib_mic.png"
         style="height: 4.4rem; margin-bottom: 0.8rem;"
         @click="handleStart" />
        <p>点击按钮开始朗读</p>
      </div>
      <div v-else-if="flag=='record'" class="box-record">
        <div class="audi">
          <img v-if="doing" src="@/assets/ib_pause.png"
            @click="handlePause" />
          <img v-else src="@/assets/ib_play.png"
            @click="handleResume" />
          <span >{{ recordingTime || '00:00:00' }}</span>
        </div>
        <div>
          <button style="background: #377EEA; border-radius: 1.5rem; font-weight: 400; font-size: 14px; color: #FFFFFF; padding: 0.5rem 1.9rem;"
            @click="handleStop">朗读完成</button>
        </div>
      </div>
      <div v-else-if="flag=='stop'" class="box-record">
        <div class="audi">
          <img v-if="doing" src="@/assets/ib_pause.png"
            @click="onPause" />
          <img v-else src="@/assets/ib_play.png"
            @click="onPlay" />
          <span >{{ countdown }}</span>
        </div>
        <p style="line-height: 1.3; font-size: 0.6rem;">温馨提示：如果复听录音，声音效果不理想<br/> 可以<a style="color: #377EEA" @click="onAgain">重新采集</a></p>
      </div>
    </div>

    <audio style="visibility: hidden;" ref="audio" :src="audioSrc" controls
       @timeupdate="updateCountdown"
       @ended="restartCountdown"
       @loadedmetadata="getDuration"></audio>
    <el-dialog
      class="box-dialog"
      width="70%"
      :visible.sync="dialogVisible"
      :show-close="false" >
      <div style="font-size: 0.7rem; color: #000; line-height: 1.2; text-align: center;">重新采集会覆盖之前已采集的录音，请确认是否重新采集？</div>
      <span slot="footer" class="footer">
        <el-button @click="dialogVisible = false" style="color: #808080">取 消</el-button>
        <el-button @click="onConfirm">确 定</el-button>
      </span>
    </el-dialog>
</div>    
</template>

<script>
import Vue from 'vue'
import { postForm, uploadFile } from '@/api'
import Cookies from 'js-cookie'
import Recorder from 'recorder-core'
import 'recorder-core/src/engine/wav'

export default {
  data(){
    return {
      isDealer: false,
      isAdmin: false,
      flag: 'start',
      recordingTime: null,
      doing: false,
      dialogVisible: false,
      audioSrc: '',
      countdown: null,
      detail: {},
    }
  },
  components:{
  },
  methods: {
    formatMs(ms){
       if (!ms) {
        return '00:00:00';
      }
      const ss=ms%1000;ms=(ms-ss)/1000;
      const s=ms%60;ms=(ms-s)/60;
      const m=ms%60;ms=(ms-m)/60;
      const h=ms;
      const pad = (n) => (n < 10 ? '0' + n : n);
      return pad(h) + ':' + pad(m) + ':' + pad(s);
		},
    onAgain() {
      this.dialogVisible = true;
      this.onPause();
    },
    updateCountdown() {
      const audio = this.$refs.audio;
      if (audio) {
        const currentTime = audio.currentTime;
        this.totalTime = audio.duration;
        this.countdown = formatTime(Math.floor(this.totalTime - currentTime));
      }
    },
    restartCountdown() {
      this.doing = false;
      this.countdown = formatTime(this.audioLen);
    },
    getDuration(event) {
      const audio = event.target;
      this.audioLen = Math.floor(audio.duration);
      this.countdown = formatTime(this.audioLen);
    },
    onConfirm() {
      this.dialogVisible = false;
      this.flag = 'start';
    },
    onPause() {
      this.$refs.audio.pause();
      this.doing = false;
    },
    onPlay() {
      this.$refs.audio.play();
      this.doing = true;
    },
    async handleStart() {
      this.recorder = new Recorder({
        type: 'wav',
        sampleBits: 16,
        sampleRate: 16000,
        onProcess: (buffers,powerLevel,duration,sampleRate) => {
          this.duration=duration;
          this.recordingTime=this.formatMs(duration);
        }
      });

      this.recorder.open(() => {
          if(!this.recorder || !Recorder.IsOpen()){
            this.$message.info("未打开录音");
            return
          }
          this.recorder.start();
          this.flag = 'record';
          this.doing = true;
      }, (msg) => {
        this.$message({
          message: "无法录音:"+msg,
          type: 'info'
        })
      })
    },
    handlePause() {
      if(!this.recorder || !Recorder.IsOpen()){
        this.$message.info("未打开录音");
        return
      }
      this.doing = false;
      this.recorder.pause()
    },
    handleResume() {
      if(!this.recorder || !Recorder.IsOpen()){
        this.$message.info("未打开录音");
        return
      }
      this.doing = true;
      this.recorder.resume()
    },
    handleStop() {
      if(!this.recorder || !Recorder.IsOpen()){
        this.$message.info("未打开录音");
        return
      }
      if (this.recorder) {
        this.recorder.stop((blob, duration) => {
          const newbolb = new Blob([blob], { type: 'audio/wav' })
          const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
          uploadFile('/h5/admin/setFeature', fileOfBlob, 'file').then(({data}) => {
            this.recorder.close();
            this.recorder=null;
            if(!data.status) {
              this.$message.error(data.msg);
              this.flag = 'start';
              return;
            }
            this.flag = 'stop';
            this.$router.push({name: 'member'});
          });
        },(err)=>{
          this.$message.error("结束录音出错："+err);
          this.recorder.close();
          this.recorder=null;
          this.flag = 'start';
        });
      }
      this.recorder.stop();
    },
  },
  created() {
    const {state} = this.$router.currentRoute.query || {};
    if (state == 1) {
      this.flag = 'stop';
      postForm('/h5/admin/getFeatureInfo').then(res => {
          if (res.status === 1) {
            this.detail = res.data || {};
            this.audioSrc = this.detail.feature_file_path;
          } else {
            this.$message.error(res.msg);
          }
        });
    }
  }
  
}

function formatTime(timeInSeconds) {
  if (!timeInSeconds) {
    return '00:00:00';
  }

  const pad = (n) => (n < 10 ? '0' + n : n);
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return pad(hours) + ':' + pad(minutes) + ':' + pad(seconds);
}
</script>

<style lang="less">
.page-voice {
  position: relative;

  .box-dialog {
    .el-dialog {
      border-radius: 0.7rem;
    }
    .el-dialog__footer, .el-dialog__header {
      padding: 0;
    }
    .footer {
      border-top:1px solid #DEDEDE;
      display: flex;
      button {
        flex: 1;
        border: 0;
        line-height: 2.1rem;
        padding: 0;
        background: transparent;
      }
    }
  }

  
  .box-title {
    color: #000;
    text-align: center;
    font-size: 0.8rem;
    padding: 1rem;
  }

  .sec {
    font-size: 0.8rem;
    font-weight: 500;

    &.tip {
      margin-top: 1rem;
      padding: 0.8rem;
      font-size: 0.7rem;
      line-height: 1.2;

      p {
        font-size: 0.6rem;
        color: #999;
      }
    }
    &.opts {
      text-align: center;
      padding: 4.5rem 0.5rem;
      > div {

        p {
          color: #666666;
          font-size: 0.7rem;
        }
      }
    }

    .box-record {
      .audi {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        img {
          height: 1.4rem;
          margin-right: 0.7rem;
        }
      }
    }
  }
}

</style>
